import React from "react";
import logo from "../logo.png";

const Header = () => (
  <header className="bg-zinc-900/90 text-zinc-100 py-2 px-6 shadow-lg md:sticky top-0 z-50 hidden md:block">
    <div className="max-w-7xl mx-auto flex justify-between items-center">
      <div className="flex items-center">
        <img src={logo} alt="Pensaccio Logo" className="h-16 w-16" />
        <span className="hidden md:inline-block text-xl">Pensaccio</span>
      </div>
      <nav>
        <ul className="flex">
          <li>
            <a href="#cta-section" className="bg-purple-900 hover:bg-purple-700 px-4 py-2 rounded-lg transition-colors">
              Dive in
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;
