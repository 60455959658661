import React from "react";

const VideoDemoSection = () => {
  const videoId = "OHzTJfgDsGM";

  return (
    <section className="py-16 even:bg-zinc-900 odd:bg-zinc-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-white mb-8 text-center">How It Feels</h2>
        <div className="max-w-7xl mx-auto">
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="fullscreen; picture-in-picture"
            className="w-full rounded-lg shadow-lg aspect-video"
            title="Pensaccio Streaming Demo"
          ></iframe>
        </div>
        <p className="text-white text-center mt-6 max-w-2xl mx-auto">
          Experience the real-time streaming while generating summaries of the artifact
        </p>
      </div>
    </section>
  );
};

export default VideoDemoSection;
