import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import screenshot1 from "./images/1-root.webp";
import screenshot2 from "./images/2-seeker.webp";
import screenshot3 from "./images/3-lore-vault.webp";
import screenshot4 from "./images/4-artifact-head.webp";
import screenshot5 from "./images/5-artifact-tail.webp";
import screenshot6 from "./images/6-sentinels.webp";
import screenshot7 from "./images/7-sentinel.webp";

const screenshots = [
  {
    src: screenshot1,
    alt: 'A dark-themed web interface featuring two card designs. The left card, titled "The Seeker", depicts a cloaked figure holding a staff, surrounded by a starry night sky and ocean waves. The right card, titled "Lore Vault," shows an intricate circular mechanism resembling a vault door, with celestial patterns surrounding it.',
    description: "Choose your path - start screen",
  },
  {
    src: screenshot2,
    alt: 'A similar web interface as the first, now displaying two different card designs. The left card, titled "Explorations", shows a hooded figure holding a lantern, walking down an ancient pathway with celestial symbols overhead. The right card, titled "Sentinels,", features a cloaked figure accompanied by an owl, sitting peacefully amidst foliage and starlight.',
    description: "The Seeker - queries root screen",
  },
  {
    src: screenshot3,
    alt: "A dark-mode user interface showcasing a list of saved artifacts. Each artifact has a title, date, and associated tags in purple bubbles. The entries are listed in rows, and each row has options for marking the artifact as read or deleting it.",
    description: "Lore Vault - where the artifacts are stored",
  },
  {
    src: screenshot4,
    alt: 'Top section of a detailed view of a specific artifact titled "Explore Nix\'s benefits and practical applications for developers in Elixir." It features a title, TL;DR, digest and summary of the article. The user interface is dark-themed with purple elements.',
    description: "Example Artifact - Head with title and TL;DR",
  },
  {
    src: screenshot5,
    alt: "Bottom section of a detailed view of a specific artifact. It features article's metadata like tags, author and date. The user interface is dark-themed with purple highlights for tags.",
    description: "Example Artifact - Metadata",
  },
  {
    src: screenshot6,
    alt: 'A dark-mode interface showing a list of "Sentinels", each with a name, current mission, and an option to assign a new mission. Each sentinel is represented by a circular avatar featuring unique designs and mystical themes.',
    description: "Sentinels - the persistent queries",
  },
  {
    src: screenshot7,
    alt: 'A detailed view of a specific Sentinel named "Nyx", featuring a portrait of a hooded figure accompanied by an owl. The interface shows the sentinel\'s current mission ("Optimizing performance of LLM applications") and a list of relevant articles with scores and titles.',
    description: "Sentinel - watching over a specific query",
  },
];

const ScreenshotCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + screenshots.length) % screenshots.length);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % screenshots.length);
  };

  return (
    <div className="relative w-full max-w-[1200px] mx-auto border border-zinc-100/15">
      <div className="overflow-hidden rounded-lg shadow-lg">
        <img src={screenshots[currentIndex].src} alt={screenshots[currentIndex].alt} className="w-full h-auto" />
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-30 text-white p-4">
        <p className="text-center">{screenshots[currentIndex].description}</p>
      </div>
      <button
        onClick={goToPrevious}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={goToNext}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        <ChevronRight size={24} />
      </button>
    </div>
  );
};

const ScreenshotsSection = () => {
  return (
    <section className="even:bg-zinc-900 odd:bg-zinc-800 text-zinc-100 px-4 ">
      <div className="max-w-7xl mx-auto py-12">
        <h2 className="text-4xl font-bold text-center mb-12">How It Looks</h2>
        <div className="mt-12">
          <ScreenshotCarousel />
        </div>
      </div>
    </section>
  );
};

export default ScreenshotsSection;
