import React from "react";
import { BookOpenIcon, SearchIcon, SparklesIcon } from "lucide-react";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-zinc-800 p-6 rounded-lg">
    <Icon className="h-12 w-12 text-purple-500 mb-4 mx-auto md:mx-0" />
    <h3 className="text-xl font-semibold mb-2 text-center md:text-left">{title}</h3>
    <p className="text-zinc-300 text-center md:text-left">{description}</p>
  </div>
);

const FeatureHighlights = () => (
  <section className="even:bg-zinc-900 odd:bg-zinc-800 text-zinc-100 py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-bold text-center mb-12">Harness the Power of Knowledge</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <FeatureCard
          icon={BookOpenIcon}
          title="Artifact Storage"
          description="Capture and preserve web content with a simple incantation (or link)."
        />
        <FeatureCard
          icon={SparklesIcon}
          title="Alchemical Summaries"
          description="Transmute lengthy texts into concise, potent knowledge extracts."
        />
        <FeatureCard
          icon={SearchIcon}
          title="Seek Wisdom"
          description="Run explorations and send sentinels to unearth hidden gems in your personal vault."
        />
      </div>
    </div>
  </section>
);

export default FeatureHighlights;
