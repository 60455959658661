import Helmet from "react-helmet";

const WaitlistWidget = () => {
  return (
    <>
      <div
        id="getWaitlistContainer"
        data-waitlist_id="21084"
        data-widget_type="WIDGET_1"
        data-signup_email_placeholder_value="dumbledore@hmail.com"
        class="max-w-[600px] mx-auto"
      ></div>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
        />
        <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
      </Helmet>
    </>
  );
};

export default WaitlistWidget;
