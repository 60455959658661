import React from "react";
import { CircleHelpIcon } from "lucide-react";

const FAQItem = ({ question, answer }) => {
  return (
    <div className="mb-8">
      <div className="flex items-start mb-2">
        <CircleHelpIcon className="w-6 h-6 text-purple-500 mr-2 flex-shrink-0" />
        <h3 className="text-lg font-semibold text-zinc-100">{question}</h3>
      </div>
      <p className="text-zinc-300 ml-8">{answer}</p>
    </div>
  );
};

const FAQSection = () => {
  const faqItems = [
    {
      question: "Is the app open to everyone?",
      answer:
        "Currently the app is in the closed beta phase. You can sign up for the waitlist to receive an invitation when spots become available. We are working to open the app to everyone soon.",
    },
    {
      question: "What is the limit of stored artifacts?",
      answer:
        "Currently, we offer storage for up to 10000 artifacts per user account. This limit ensures optimal performance while providing ample space for most users' needs.",
    },
    {
      question: "How is it different from bookmark tools like Pocket?",
      answer:
        'The core idea of Pensaccio is to build your personal knowledge base, help you process it and allow you to quickly come back to the information you need. It is not supposed to store any random link, but only the content that you want to process, remember and use in the future. Still, you can use it as a "bookmark tool on steroids" if you want to.',
    },
    {
      question: "Can it integrate with tools like Readwise?",
      answer:
        "Currently, we are offering a simple API which allows to add new artifacts programmatically. It can be added to your existing personal knowledge workflows. We will be working on adding the more robust integration options based on the feedback from user in the beta phase.",
    },
    {
      question: "Can Pensaccio read content behind the paywall or requiring login?",
      answer:
        "You can add the content from behind the paywall manually by copying the text and adding it in the New Artifact form. In the future we are planning to add a more robust solution to that problem.",
    },
    {
      question: "Is the app supporting other languages than English?",
      answer:
        "Currently the app can process the content in any language, but it will create summaries only in English. We will be looking for the feedback from beta stage users before changing it, as mixing languages can be problematic for the search experience.",
    },
  ];

  return (
    <section className="py-16 even:bg-zinc-900 odd:bg-zinc-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-zinc-100">Frequently Asked Questions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-12">
          {faqItems.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
